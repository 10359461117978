$focused-outline-color: #333;

.action {
  display: flex;
  width: 12px;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 2px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) {
    &:hover {
      background-color: var(--action-background, #00000012);
    }
  }

  svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: #999999;
  }

  &:active {
    background-color: var(--action-background, #00000012);
  }

  &:focus-visible {
    outline: none;
    background-color: var(--action-background, #00000012);
    box-shadow:
      0 0 0 2px rgba(255, 255, 255, 0),
      0 0px 0px 2px $focused-outline-color;
  }
}

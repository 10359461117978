.container {
  display: flex;
  flex-direction: column;
  grid-auto-rows: max-content;
  overflow: hidden;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  border-radius: 6px;
  min-height: 150px;
  transition: background-color 350ms ease;
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 1em;

  ul {
    display: grid;
    grid-gap: 0.75rem;
    padding: 0.75rem;
    grid-template-columns: repeat(1, 1fr);
    list-style: none;
    margin: 0;
  }

  &.hover {
    background-color: rgb(235, 235, 235, 1);
  }

  &.shadow {
    box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
  }

  &:focus-visible {
    border-color: transparent;
    box-shadow:
      0 0 0 2px rgba(255, 255, 255, 0),
      0 0px 0px 2px #333;
  }
}

.containerHeader {
  display: flex;
  padding: 12px 16px;
  padding-right: 8px;
  align-items: stretch;
  justify-content: space-between;
  background-color: #fff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:hover {
    .containerActions > * {
      opacity: 1 !important;
    }
  }
}

.containerActions {
  display: flex;
  gap: 2px;

  > *:first-child:not(:last-child) {
    opacity: 0;

    &:focus-visible {
      opacity: 1;
    }
  }
}

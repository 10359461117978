html,
body {
  background-image: linear-gradient(180deg, rgba(247, 244, 240, 0.93), rgba(247, 244, 240, 0.93)),
    url(../public/img/noise.webp);
  background-size: auto, 125px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100vh;
}

* {
  margin: 0;
  box-sizing: border-box;
  word-break: break-all;
}

ul,
ol {
  list-style-type: disc;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

li {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
  font-family: inherit;
}

a:visited {
  color: inherit;
}

a:focus-visible,
*[role='button']:focus-visible,
button:focus-visible {
  outline-width: 2px;
  outline-style: solid;
  outline-offset: 1px;
}

button {
  font-family: inherit;
}

video,
img,
svg {
  object-fit: contain;
  vertical-align: bottom;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='number'],
input[type='date'],
input[type='url'],
input[type='text'] {
  color: #333333;
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
}

input[type='number']:not(.MuiOutlinedInput-input),
input[type='date']:not(.MuiOutlinedInput-input),
input[type='url']:not(.MuiOutlinedInput-input),
input[type='text']:not(.MuiOutlinedInput-input) {
  border: solid 1px #cecece;
  padding: 6px;
}

input[type='date'][value=''],
input[type='time'][value=''],
input[type='datetime-local'][value=''] {
  color: #cecece;
}

textarea::placeholder,
input::placeholder {
  color: #cecece !important;
}

input[type='search']::-webkit-search-cancel-button {
  height: 16px;
  width: 16px;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba(153,153,153,1)'%3E%3Cpath d='M10.5859 12L2.79297 4.20706L4.20718 2.79285L12.0001 10.5857L19.793 2.79285L21.2072 4.20706L13.4143 12L21.2072 19.7928L19.793 21.2071L12.0001 13.4142L4.20718 21.2071L2.79297 19.7928L10.5859 12Z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  padding-right: 0px;
}

input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: #c9c9c9;
  height: 12px;
  width: 100%;
  border-radius: 999px;
  border: solid 3px #ececec;
  // outline: 0; /* アウトラインを消して代わりにfocusのスタイルをあてる */
  // &:focus {
  //   box-shadow: 0 0 3px rgb(0, 161, 255);
  // }
  // -webkit-向けのつまみ
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #5a5a5a;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }
  // -moz-向けのつまみ
  &::-moz-range-thumb {
    background: #5a5a5a;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    border: none; // デフォルトの線を消す
  }
  // Firefoxで点線が周りに表示されてしまう問題の解消
  &::-moz-focus-outer {
    border: 0;
  }
  // つまみをドラッグしているときのスタイル
  &:active::-webkit-slider-thumb {
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.5);
  }
}

/*Navigation.tsx*/
@media (hover: hover) {
  .navigationLink:hover {
    background-color: #76767657 !important;
  }
}

.navigationLink.active * {
  filter: none !important;
}

.navigationLink:focus-visible {
  outline: 2px solid #fff;
  outline-offset: 1px;
}

.navigationLink.Mui-focusVisible {
  background-color: transparent;
}

.MuiChartsTooltip-root * {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.draggableMenuContainer {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);

  ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
    list-style: none;
    margin: 0;
  }

  &.noCategory {
    padding: 6px;
    min-height: 50px;
    border: none !important;
  }

  &.hover {
    background-color: #f3f3f3;
  }

  &.shadow {
    background-color: #fff;
    box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
  }

  &:focus-visible {
    border-color: transparent;
    box-shadow:
      0 0 0 2px rgba(255, 255, 255, 0),
      0 0px 0px 2px #333;
  }
}

.draggableMenuContainerHeader {
}

.draggableMenuContainerActions {
  display: flex;
  gap: 2px;
}

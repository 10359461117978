.ag-root-wrapper {
  border-radius: 0 0 8px 8px !important;
  overflow: visible;
}

.ag-cell-value {
  line-height: 1.65;
  padding: 8px;
}

.ag-cell-wrapper > .ag-cell-value {
  padding: 8px 0px;
}

.ag-cell-value a {
  text-decoration: underline;
}

.ag-cell-value > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ag-cell-wrapper.ag-checkbox-cell {
  width: 100%;
  justify-content: center;
}
.ag-cell-inline-editing {
  padding: 0;
}

.ag-pinned-left-header {
  border-right: solid 1px #d1d1d1;
}
.ag-cell.ag-cell-last-left-pinned:not(
    .ag-cell-range-right,
    .ag-cell-range-single-cell,
    .ag-cell-focus:not(.ag-cell-range-selected):focus-within
  ) {
  border-right: solid 1px #d1d1d1;
}

.ag-input-wrapper:before {
  opacity: 0;
}

@media (hover: hover) {
  #tableMenu-button:hover {
    background-image: linear-gradient(180deg, rgba(80, 80, 80, 0.93), rgba(80, 80, 80, 0.93)),
      url(../../../../public/img/noise.webp) !important;
  }
}
